<template>
  <span class="mt-2">
    <span
      v-for="r in [{name: $t('library.library'), id: null}, ...route]"
      :key="r.id"  
    >
      <v-chip
        @click="handleClick(r)"
        class="mt-2"
        lg
      >
        {{r.name}}
      </v-chip>
      <v-icon class="mt-2">
        mdi-chevron-right
      </v-icon>
    </span>
  </span>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('folder', [
      'route'
    ])
  },
  methods: {
    ...mapMutations('folder', [
      'OPEN_FOLDER'
    ]),
    ...mapActions('folder', [
      'getFolders'
    ]),
    handleClick(r) {
      this.OPEN_FOLDER(r.id); 
      this.getFolders(r.id); 
      this.$router.replace({
        query: { id: r.id || 'root', type: 'folder' }
      })
    }
  }
}
</script>