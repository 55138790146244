<template>
  <v-container fluid style="height: 100%; width: 100%; max-width: 1400px; overflow: auto;" :class="`hideScroll ${$vuetify.theme.dark ? 'dark' : ''}`">
    <v-row
      style="width: 100%;"
      class="mb-0 pt-8 pl-3 mt-3"
    >
      <v-menu offset-y class="mt-2">
        <template v-slot:activator="{ on, attrs }">
          <div :style="$vuetify.breakpoint.mobile ? '' : 'display: flex; align-items: center;'">
            <v-btn
              v-if="!view_only"
              v-bind="attrs"
              v-on="on"
              color="primary"
              fab
              class="mr-4 mt-1"
              v-tooltip="$t('create')"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              fab
              small
              :disabled="!canPresent"
              @click="$router.push('/presentation/' + (openedFolderId || 'root'))"
              v-tooltip="$t('library.presentation')"
              class="mr-4 mt-1"
            >
              <v-icon>
                mdi-presentation-play
              </v-icon>
              <!-- {{$t('library.presentation')}} -->
            </v-btn>
            <v-btn
            v-if="!selecting"
            fab
              small
              @click="setSelecting"
              v-tooltip="$t('library.batch')"
              class="mr-8 mt-1"
              :color="selecting == true ? 'primary' : ''"
            >
              <v-icon>
                mdi-check
              </v-icon>
            </v-btn>
            <v-btn
            v-else
            fab
              small
              @click="setSelecting(); allSelected = false"
              v-tooltip="$t('library.batch_cancel')"
              class="mr-8 mt-1"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
            <!-- <v-btn v-else
              variation="disabled"
              @click="cantOpen"
              class="mr-8 mt-1"
              >
              {{$t('library.presentation')}}
            </v-btn> -->
            <FolderRoute />
            <v-text-field             
            filled
            rounded
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('search')"
            style="display: block"
            v-model="search"
            :class="$vuetify.breakpoint.mobile ? 'mt-3' : 'pt-10 mr-2 ml-0'"
            >
            </v-text-field>
          </div>
        </template>
        <v-list>
          <v-list-item
            @click="adding = true"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('library.new_folder')}}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="showClipAdder = true" link>
            <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted</v-icon>
              <v-icon size="10">mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('library.add_clip')}}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="creatingFile = true" link>
            <v-list-item-icon>
              <v-icon>mdi-file-document-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('library.add_file')}}</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-timer-outline</v-icon>
              <v-icon size="10">mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('library.add_time')}}</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
    </v-row>
    <div class="ma-0 mb-4 pa-0" v-if="selecting">
      <v-btn
        v-if="selecting"
        class="ml-2 mt-2"
        :disabled="selectedItems.length < 1"
        @click="deleting = true"
        color="red"
      >
        {{ $t('delete') }}
        <v-icon class="ml-1" small>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="selecting"
        class="ml-2 mt-2"
        :disabled="selectedItems.length < 1"
        @click="choosing = true"
      >
        {{$t('library.add_to_folder')}}
        <v-icon class="ml-1" small>mdi-folder-outline</v-icon>
      </v-btn>
      <v-btn
      class="ml-2 mt-2"
      @click="selectAll"
      >
        {{ allSelected ? $t('library.cancel_select_all') : $t('library.select_all')}}
      </v-btn>
    </div>
    <div v-if="!loading && !folders.length && !openedFolderId" style="text-align: center;" class="pt-10">
      <v-icon size="100">
        mdi-folder-outline
      </v-icon>
      <h2>
        {{$t('library.add_folder')}}!
      </h2>
      <v-btn
        @click="adding = true"
        color="primary"
        class="mt-6 mb-5"
      >
        {{$t('add')}}
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-row>
      <draggable
        style="width: 100%; display: flex; flex-wrap: wrap; margin-bottom: 80px;"
        :value="filteredItems"
        tag="div"
        :handle="$vuetify.breakpoint.mobile ? '.handle' : null"
        v-bind="dragOptions"
        @input="folderOrderUpdate($event)"
      >
        <!-- <transition-group name="list" tag="div"> -->
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            style="padding: 10px;"
            v-for="(folder, i) in filteredItems"
            :key="folder.id + i.toString()"
          >
            <FolderInstance
              :folder="folder"
              @toggleSelection="handleSelection(folder)"
            ></FolderInstance>
          </v-col>
        <!-- </transition-group> -->
      </draggable>
    </v-row>
    <!-- <FolderClips 
      :clips="clips"
    /> -->

    <!-- ADD FOLDER DIALOG -->
    <v-dialog v-model="adding" max-width="400">
      <v-card>
        <v-card-title>
          <v-icon>
            mdi-plus
          </v-icon>
          {{$t('library.new_folder')}}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newFolderName"
            autofocus
            placeholder="New folder"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="adding = false">
            {{$t('cancel')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="startAddFolder()" color="primary">
            {{$t('add')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <clip-adder
      :show="showClipAdder"
      :currentClips="this.folders.filter(f => f.type == 'clip')"
      @close="showClipAdder = false"
      @add="addClips($event)"
    ></clip-adder>
    <v-dialog fullscreen persistent max-width="1000" :value="creatingFile">
      <!-- { title: 'yea', text: '' } -->
      <CreateTextFile
        v-model="textFile"
        @close="creatingFile = false"
        :editing="false"
      ></CreateTextFile>
    </v-dialog>
    <ConfirmationDialog
      :show="deleting"
      :subText="$t('library.confirm_batch_delete')"
      btnColor="red"
      :btnText="$t('delete')"
      :text="$t('library.delete_folder')"
      @decline="deleting = false"
      @accept="acceptDeleting()"
    />
    <ChooseFolder
      :visible="choosing"
      @closed="choosing = false"
      @chosen="addAllToFolder($event)"
      :text="$t('analysis.add_to_folder')"
      />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FolderInstance from '@/components/folder/FolderInstance'
import FolderRoute from '@/components/folder/FolderRoute'
import ClipAdder from '../components/folder/ClipAdder.vue'
import CreateTextFile from '../components/textfile/CreateTextFile.vue'
import draggable from 'vuedraggable'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import ChooseFolder from '@/components/folder/ChooseFolder.vue'
// import ShareBtn from '@/components/share/ShareBtn.vue'

export default {
  props: ['folder_id', 'view_only'],
  components: { 
    CreateTextFile, 
    FolderInstance, 
    FolderRoute, 
    ClipAdder, 
    draggable, 
    ConfirmationDialog,
    ChooseFolder
    // ShareBtn 
  },
  created() {
    this.loading = true
    this.getAllFolders()
      .then(e => {
        let { id } = this.$route.query
        id = id || this.folder_id
        let open = e.data.find(f => f.id == id)

        if(!open) return

        if(open.type !== 'folder') {
          this.openFolder(open.parent)
        } else {
          this.openFolder(open.id)
        }
      })
      .catch(e => {
        this.error(e)
      })
      .finally(() => this.loading = false)
  },
  data: () => ({
    textFile: {
      title: 'No title',
      text: ''
    },
    creatingFile: false,
    adding: false,
    newFolderName: '',
    chosenFolder: '',
    loading: false,
    showClipAdder: false,
    dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
    },
    search: '',
    deleting: false,
    choosing: false,
    allSelected: false
  }),
  methods: {
    ...mapActions('folder', [
      'getFolders',
      'addFolder',
      'openFolder',
      'getAllFolders',
      'batchAddClips',
      'updateFolderOrder',
      'setSelecting',
      'handleSelection',
      'batchDelete',
      'batchUpdateFolder',
      'addItem',
      'removeItem'
    ]),
    ...mapActions('noti', [
      'error',
      'success',
      'info'
    ]),
    cantOpen() {
      this.info("There are no items for a presentation in this folder. Please choose another folder.")
    },
    startAddFolder() {
      this.addFolder({ name: this.newFolderName, type: 'folder' })
        .then(() => {
          this.success(this.$t('library.folder_created') + '!')
          this.adding = false
          this.newFolderName = ''
        })
        .catch(e => {
          this.error(e)
        })
    },
    addClips(clips) {
      this.batchAddClips({ clips: clips, parent_id: this.openedFolderId })
        .then(() => {
          this.success(this.$t('library.clips_added'))
          this.showClipAdder = false
        })
        .catch(e => this.error(e) )
    },
    folderOrderUpdate(folders) {
      this.updateFolderOrder(folders.map(f => f.id))
    },
    acceptDeleting() {
      this.batchDelete()
      .then(() => {
        this.success(this.$t('analysis.all_deleted') + '!')
      })
      .catch((error) => {
        this.error(error)
      }).finally(
        this.deleting = false,
        this.allSelected = false,
        this.setSelecting()
      )
    },
    addAllToFolder(event) {
      if(this.selectedItems.some(item => item.id == event.id)) {
        let text = this.$t('library.self_parent_error')
        this.info(text)
        return
      }
      this.batchUpdateFolder(event.id)
      .then(() => {
        let name = event.name
        if(event.name == undefined) name = this.$t('library.library')
        this.success(this.$t('analysis.all_added') + '"' + name + '"')
      })
      .catch((error) => {
        this.error(error)
      }).finally(
        this.choosing = false,
        this.allSelected = false,
        this.setSelecting()
      )
    },
    selectAll() {
      if (!this.allSelected) {
        this.allSelected = true
        for(let items of this.filteredItems) {
          this.addItem(items)
        }
      }
      else {
        this.allSelected = false
        for(let items of this.filteredItems) {
          this.removeItem(items)
        }
      }
    },
  },
  computed: {
    ...mapGetters('folder', [
      'folders',
      'route',
      'clips',
      'openedFolderId',
      'firstChild',
      'firstChildFolderId',
      'selecting',
      'selectedItems'
    ]),
    canPresent() {
      return this.folders.some(f => f.type !== 'folder');
    },
    filteredItems()  {
      if (this.search === '' || !this.search) return this.folders
      return this.folders.filter(folder => folder.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>

<style lang="scss">
.dark {
  background-color: #121212;
}
// .list-move, /* apply transition to moving elements */
.list-enter-active
// .list-leave-active 
{
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.handle {
  &:hover {
    color: black;
  }
}
</style>