<template>
  <v-dialog
    max-width="800"
    :value="visible"
    @input="$event ? '' : $emit('cancel')"
  >
    <v-card class="py-10">
      <v-card-title>
        {{ text }}
      </v-card-title>
      <v-card-text
        style="overflow-y: scroll"
      >
        <v-chip
          :color="chosen ? 'primary' : ''"
          @click="handleClicked"
        >
          <v-icon>mdi-folder-outline</v-icon>
          {{$t('library.library')}}
        </v-chip>
        <FolderTree
          :limit="limit"
          :value="value"
          class="ml-4"
          v-for="folder in rootLevel"
          :key="folder.id"
          :folder="folder"
          @input="$emit('input', $event)"
          :disabledParentId="disabledParentId"
        ></FolderTree>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          @click="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn :disabled="!value || !value.length" @click="$emit('input', [])">
          <v-expand-x-transition>
            <v-icon v-if="!(!value || !value.length)" class="mr-2">mdi-minus</v-icon>
          </v-expand-x-transition>
          {{$t('clear')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('set')"
          color="primary"
        >
          {{ $t('ok') }}
          <v-icon class="ml-2">mdi-keyboard-return</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FolderTree from './FolderTree'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { FolderTree },
  mounted() {
    this.getAllFolders()
      .then()
      .catch(e => this.error(e))
  },
  props: ['value', 'visible', 'text', 'disabledParentId', 'limit'],
  shortcuts: {
    enter: function() {
      if(this.keybinds) {
        this.$emit('set')
        return false
      }
    }
  },
  methods: {
    handleClicked() {
      const folder = { id: undefined, name: this.$t('library.library') }
      if(!this.value) this.$emit([ folder ])

      const wasChosen = this.value.map(f => f.id).includes(undefined)
      
      if(wasChosen) {
        this.$emit('input', this.value.filter(f =>  !!f.id))
      }
      else if(this.limit && this.value.length === this.limit) this.$emit('input', [...this.value].concat(folder).slice(1))
      else this.$emit('input', [...this.value].concat(folder))
    },
    ...mapActions('folder', [
      'getAllFolders'
    ]),
    ...mapActions('noti', [
      'error'
    ]),
    setChosen(e) {
      this.$emit('chosen', e)
    }
  },
  computed: {
    ...mapGetters('folder', [
      'folders',
      'structuredFolders',
      'rootLevel'
    ]),
    ...mapGetters(['keybinds']),
    chosen() {
      if(!this.value) return false
      else if (!this.value.length) return false
      return this.value.map(f => f.id).includes(undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>