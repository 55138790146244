<template>
  <div class="my-1">
    <v-chip
      :disabled="isDisabled"
      @click="handleClicked()"
      :color="chosen ? 'primary' : ''"
    >
      <v-icon class="mr-3">mdi-folder-outline</v-icon>
      {{ folder.name }}
    </v-chip>
    <div style="border-left: 1px dashed grey;" v-if="children(folder.id) && children(folder.id).length">
      <FolderTree
        @input="$emit('input', $event)"
        class="ml-6"
        :value="value"
        v-for="child in children(folder.id)"
        :key="child.id"
        :folder="child"
        :disabledParentId="isDisabled ? child.id : disabledParentId"
      ></FolderTree>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FolderTree',
  props: ['folder', 'opened', 'disabledParentId', 'value', 'limit'],
  methods: {
    handleClicked() {
      if(!this.value) {
        this.$emit('input', [ this.folder ])
        return
      }

      const wasChosen = this.value.map(f => f.id).includes(this.folder.id)
      
      if(wasChosen) this.$emit('input', this.value.filter(f => f.id !== this.folder.id))
      else if(this.limit && this.value.length >= this.limit) this.$emit('input', [...this.value].concat(this.folder).slice(1))
      else this.$emit('input', [...this.value].concat(this.folder))
    }
  },
  computed: {
    ...mapGetters('folder', [
      'children',
      'selectedItems'
    ]),
    isDisabled() {
      if (this.selectedItems.some(item => item.id == this.folder.id)) return true
      return this.disabledParentId == this.folder.id
    },
    chosen() {
      if(!this.value) return false
      return this.value.map(f => f.id).includes(this.folder.id)
    }
  }
}
</script>